import { Dropdown } from "react-bootstrap"
import { Link } from "react-router-dom"
import { EVENTSTATUS_LIVE, EVENTSTATUS_UPCOMING, EVENTSTATUS_VODS, EVENTXRMODE_AR } from "../../utils/constants"

export default function EventLink(props) {
    let link = null
    let linkExtra = null
    let firstConfig = null
    // console.log(props.event)
    // console.log(props.event.configs)
    if (props.event.status === EVENTSTATUS_UPCOMING) {
        link = (
            <button className="btn btn-link text-dark text-uppercase" data-event={JSON.stringify(props.event)} onClick={props.handleSubscribeClick}>
                Subscribe
            </button>
        )
    } else if (Object.values(props.event.configs).length) {
        firstConfig = Object.values(props.event.configs)[0]
        switch (props.event.status) {
            case EVENTSTATUS_LIVE:
                if (firstConfig.xrMode === EVENTXRMODE_AR) {
                    link = (
                        <Link className="text-dark" to={`/${props.sportId}/ar`}>
                            Go to AR Page
                        </Link>
                    )
                } else {
                    link = (
                        <Link className="text-dark text-decoration-none" to={`/${props.sportId}/live`}>
                            Watch Live
                        </Link>
                    )
                    if (Object.values(props.event.configs).length > 1) {
                        linkExtra = (
                            <Dropdown className="d-inline-block">
                                <Dropdown.Toggle
                                    variant="clear"
                                    style={{
                                        padding: "0px",
                                        marginTop: "-5px",
                                        marginLeft: "10px"
                                    }}
                                >
                                    <span className="text-uppercase">Or by team</span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {Object.entries(props.event.configs).map(([cId, c], i) => {
                                        return (
                                            <Dropdown.Item key={i} href={`/${props.sportId}/live/${cId}`} className="dropdown-item">
                                                Watch - {c.team.name}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        )
                    }
                }
                break
            case EVENTSTATUS_VODS:
                firstConfig = { id: Object.keys(props.event.configs)[0], ...Object.values(props.event.configs)[0] }
                if (firstConfig.xrMode === EVENTXRMODE_AR) {
                    link = (
                        <Link className="text-dark" to={`/${props.sportId}/ar`}>
                            Go to AR Page
                        </Link>
                    )
                } else {
                    if (Object.values(props.event.configs).length === 1) {
                        link = (
                            <Link className="text-dark text-decoration-none" to={`/${props.sportId}/vod/${firstConfig.id}`}>
                                Watch VOD
                            </Link>
                        )
                    } else if (Object.values(props.event.configs).length > 1) {
                        linkExtra = (
                            <Dropdown className="d-inline-block">
                                <Dropdown.Toggle
                                    variant="clear"
                                    style={{
                                        padding: "0px",
                                        marginTop: "-5px",
                                        marginLeft: "10px"
                                    }}
                                >
                                    <span className="text-uppercase">Watch VOD by team</span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {Object.values(props.event.configs).map((c, i) => {
                                        return (
                                            <Dropdown.Item key={i} href={`/${props.sportId}/vod/${c.id}`} className="dropdown-item">
                                                Watch - {c.team.name}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        )
                    }
                }
                break
            case EVENTSTATUS_UPCOMING:
                link = (
                    <button className="btn btn-link text-dark text-uppercase" data-event={JSON.stringify(props.event)} onClick={props.handleSubscribeClick}>
                        Subscribe
                    </button>
                )
                break
            default:
                break
        }
    }
    return (
        <>
            {link}
            {linkExtra}
        </>
    )
}
