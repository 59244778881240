import { useEffect } from "react"
import { Link } from "react-router-dom"
import Layout from "../components/Layout/Layout"
import { fetchSportsData } from "../store/sports-slice"
import { useSelector, useDispatch } from "react-redux"
import { authActions } from "../store/auth-slice"

export default function Landing() {
    const dispatch = useDispatch()
    const sports = useSelector((state) => state.sports)
    const auth = useSelector((state) => state.auth)
    useEffect(() => {
        if (!sports.loaded) dispatch(fetchSportsData())
        if (!auth.loaded) dispatch(authActions.refreshAuthFromToken())
    }, [sports.loaded, auth.loaded, dispatch])

    return (
        <Layout landingPage={true}>
            <div className="container mt-5 pt-5">
                <div className="row">
                    <div className="d-flex justify-content-center">
                        <div className="text-center">
                            <img src={`${process.env.REACT_APP_LOCATION_S3}/core/images/logo.png`} width="200px" alt="FansXR" />
                            <h4>
                                <small>Taking you into the action</small>
                            </h4>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    {sports.data.map((s, i) => {
                        const linkHref = `/${s.url_shortcut}/events`
                        return (
                            <div className="col text-center" key={i}>
                                <Link to={linkHref}>
                                    <img src={s.icon} width="50px" height="50px" alt={s.url_shortcut} />
                                    <h5 className="mt-4">{s.name}</h5>
                                </Link>
                            </div>
                        )
                    })}
                    {auth.data && auth.data.isStaff ? (
                        <div className="col text-center">
                            <Link to="/admin">
                                <img src={`${process.env.REACT_APP_LOCATION_S3}/core/images/person-fill.svg`} width="50px" height="50px" alt="admin page" />
                                <h5 className="mt-4">Admin</h5>
                            </Link>
                        </div>
                    ) : null}
                </div>
            </div>
        </Layout>
    )
}
