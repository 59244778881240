import { Carousel } from "react-bootstrap"
import EventLink from "./EventLink"

export default function CarouselCard(props) {
    let carouselImage = (
        <svg className="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="1300" height="400" xmlns="http://www.w3.org/2000/svg" focusable="false">
            <title>Placeholder</title>
            <rect width="100%" height="100%" fill="#999"></rect>
            {/* <text x="50%" y="50%" fill="#555" dy=".3em"></text> */}
        </svg>
    )

    if (props.event.img_loc)
        carouselImage = (
            <div className="d-flex justify-content-center">
                <img height="400" src={props.event.img_loc} alt={props.event.event_name} />
            </div>
        )

    return (
        <>
            {carouselImage}
            <Carousel.Caption style={{ backgroundColor: "Silver", opacity: 0.8 }}>
                <div className="d-flex justify-content-between px-5 align-content-center flex-md-row flex-column">
                    <h3 className="text-truncate text-start">
                        <span className="d-md-inline-block d-none">{props.event.event_name}:</span> {props.event.title}
                    </h3>
                    <div className="d-flex align-items-center text-uppercase">
                        <strong>{<EventLink status={props.event.status} sportId={props.sportId} event={props.event} handleSubscribeClick={props.handleSubscribeClick} />}</strong>
                    </div>
                </div>
            </Carousel.Caption>
        </>
    )
}
