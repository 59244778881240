import { useEffect, useState } from "react"
import Carousel from "../components/Events/Carousel"
import Layout from "../components/Layout/Layout"
import { ListGroup, Nav, Spinner, Tab } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { eventsActions, fetchEventsData } from "../store/events-slice"
import { useParams } from "react-router-dom"
import { EVENTSTATUS_COMPLETED, EVENTSTATUS_LIST, EVENTSTATUS_LIVE, EVENTSTATUS_UPCOMING, EVENTSTATUS_VODS } from "../utils/constants"
import EventLink from "../components/Events/EventLink"
import Subscribe from "../components/Events/Subscribe"
import { fetchConfigDataAll } from "../store/config-slice"
import moment from "moment"

export default function Events(props) {
    const dispatch = useDispatch()
    const [subscribeSelectedEvent, setSubscribeSelectedEvent] = useState(null)
    const eventsRaw = useSelector((state) => state.events)
    const configsRaw = useSelector((state) => state.config)
    const { sportId } = useParams()
    useEffect(() => {
        dispatch(eventsActions.clearEvents())
        dispatch(fetchEventsData(sportId))
        dispatch(fetchConfigDataAll(sportId))
    }, [sportId, dispatch])

    const handleSubscribeClick = (e) => {
        setSubscribeSelectedEvent(JSON.parse(e.target.getAttribute("data-event")))
    }
    const handleSubscribeClose = () => setSubscribeSelectedEvent(null)

    // let showcase = events.filter(e => e.status === EVENTSTATUS_LIVE)
    // if (!showcase.length) showcase = events.filter(e => e.status === EVENTSTATUS_UPCOMING)
    let showcase = []
    let events = []

    let content = (
        <div className="m-2">
            <Spinner animation="border" />
        </div>
    )
    for (let e of eventsRaw.data) {
        let configs = {}
        let configToPush = {}
        let status = ""
        configsRaw.data?.configs &&
            configsRaw.data.configs
                .filter((v) => e.configs.split(" ").includes(String(v.pk)))
                .forEach((v) => {
                    configs[v.pk] = {
                        ...v.fields,
                        team: {
                            ...configsRaw.data.teams.find((t) => t.pk === v.fields.team).fields
                        }
                    }
                })
        status = EVENTSTATUS_COMPLETED
        const now = moment(new Date())
        const dateStart = moment(e.date)
        const dateEnd = moment(e["date_end"])
        if (now.isBetween(dateStart, dateEnd)) status = EVENTSTATUS_LIVE
        if (now.isBefore(dateStart)) status = EVENTSTATUS_UPCOMING
        if (Object.values(configs).length > 0 && Object.values(configs)[0].type === EVENTSTATUS_VODS) status = EVENTSTATUS_VODS

        // console.log("now: ", now.format())
        // console.log("Start: ", dateStart.format())
        // console.log("end: ", dateEnd.format())
        // console.log(status)

        configToPush = { ...e, configs: configs, status: status }
        if (status === EVENTSTATUS_LIVE || status === EVENTSTATUS_UPCOMING) showcase.push(configToPush)
        events.push(configToPush)
    }

    if (eventsRaw.loaded) {
        content = (
            <>
                {subscribeSelectedEvent && <Subscribe {...props} sportId={sportId} handleSubscribeClose={handleSubscribeClose} event={subscribeSelectedEvent} />}
                <div>{showcase.length > 0 && <Carousel events={showcase} sportId={sportId} handleSubscribeClick={handleSubscribeClick} />}</div>
                <div>
                    <Tab.Container defaultActiveKey={EVENTSTATUS_LIVE}>
                        <Nav variant="pills" className={`px-5 py-2 bg-light ${showcase.length ? "border" : "border-bottom"}`}>
                            {Object.entries(EVENTSTATUS_LIST).map(([k, v]) => (
                                <Nav.Item key={k}>
                                    <Nav.Link eventKey={k}>{v}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content className="m-4">
                            {Object.keys(EVENTSTATUS_LIST).map((k) => {
                                let eventsByStatus = events.filter((e) => e.status === k)
                                console.log(eventsByStatus)
                                return (
                                    <Tab.Pane key={"content_" + k} eventKey={k}>
                                        {eventsByStatus.length ? (
                                            <ListGroup>
                                                {eventsByStatus.map((e, i) => {
                                                    return (
                                                        <ListGroup.Item className="p-4" key={`${k}_${i}`}>
                                                            <div className="d-flex justify-content-between">
                                                                <div className="text-truncate">
                                                                    <h4 className="text-truncate" title={`${e.event_name}: ${e.title}`}>
                                                                        {k === EVENTSTATUS_LIVE ? <strong>[LIVE] </strong> : ""}
                                                                        {e.event_name}: {e.title}
                                                                    </h4>
                                                                    Location: {e.location}
                                                                    <br />
                                                                    Date: {moment(e.date).local().format("YYYY-MM-DD hh:mm:ss A")}
                                                                    &nbsp;<strong>({moment(e.date).fromNow()})</strong>
                                                                </div>
                                                                <div className="d-flex align-items-center text-uppercase">
                                                                    <strong>
                                                                        {<EventLink status={k} sportId={sportId} event={e} handleSubscribeClick={handleSubscribeClick} />}
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                        </ListGroup.Item>
                                                    )
                                                })}
                                            </ListGroup>
                                        ) : (
                                            <div>There are no items for {EVENTSTATUS_LIST[k]}</div>
                                        )}
                                    </Tab.Pane>
                                )
                            })}
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </>
        )
    }

    return (
        <Layout sportId={sportId} noMarginBottom={true}>
            <div className="d-flex flex-column">{content}</div>
        </Layout>
    )
}
