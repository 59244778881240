import "aframe"
import { useEffect, useMemo } from "react"
import { Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, useParams } from "react-router-dom"
import { fetchConfigDataByDemo, fetchConfigDataById, fetchConfigDataByShowcase } from "../store/config-slice"
import { fetchSportsData } from "../store/sports-slice"
import { w3cwebsocket as W3CWebSocket } from "websocket"

import SoccerScene from "../components/AFrame/Scenes/SoccerScene"
import BaseballScene from "../components/AFrame/Scenes/BaseballScene"
import NascarScene from "../components/AFrame/Scenes/NascarScene"
import VRScene from "../components/AFrame/Scenes/VRScene"
import { EVENTSTATUS_DEMO, EVENTSTATUS_LIVE, EVENTSTATUS_VODS, STREAM_FORMAT_HLS, STREAM_FORMAT_MP4, STREAM_FORMAT_WEBRTC } from "../utils/constants"
import { parseJwt } from "../utils/connection-utils"

export default function StreamPage(props) {
    const dispatch = useDispatch()
    const sports = useSelector((state) => state.sports)
    const config = useSelector((state) => state.config)
    const { sportId, eventStatus, configId } = useParams()
    let eventStatusWithDemo = eventStatus ? eventStatus : EVENTSTATUS_DEMO
    if (eventStatus === STREAM_FORMAT_WEBRTC) eventStatusWithDemo = EVENTSTATUS_LIVE
    if (eventStatusWithDemo !== EVENTSTATUS_LIVE && eventStatusWithDemo !== EVENTSTATUS_VODS && eventStatusWithDemo !== EVENTSTATUS_DEMO) return <Redirect to="/" />
    let configFields = {}
    let teamFields = {}
    let analyticsData = { configId: "", sportId: "", teamId: "" }

    useMemo(() => {
        const websocket = new W3CWebSocket(process.env.REACT_APP_BACKEND_WSS)
        websocket.onopen = () => console.log("WebSocket Client Connected")
        websocket.onmessage = (message) => {
            const dataReceived = parseJwt(message.data)
            const [action, sportIdToCheck, configIdToCheck] = dataReceived.action.split("|")
            if (action === "configUpdate" && sportIdToCheck === sportId) {
                console.log("update Config Values")
                if (configId) dispatch(fetchConfigDataById(sportId, eventStatusWithDemo, configId))
                else dispatch(fetchConfigDataByShowcase(sportId, eventStatusWithDemo))
            } else {
                console.log("action: ", action)
                console.log("sportIdToCheck: ", sportIdToCheck)
                console.log("configIdToCheck: ", configIdToCheck)
                console.log("sportId: ", sportId)
                console.log("configId: ", configId)
            }
        }
    }, [sportId, eventStatusWithDemo, configId, dispatch])
    useEffect(() => {
        if (!sports.loaded) dispatch(fetchSportsData())
        if (configId) dispatch(fetchConfigDataById(sportId, eventStatusWithDemo, configId))
        else if (eventStatusWithDemo === EVENTSTATUS_DEMO) dispatch(fetchConfigDataByDemo(sportId, eventStatusWithDemo))
        else dispatch(fetchConfigDataByShowcase(sportId, eventStatusWithDemo))
    }, [sports.loaded, sportId, configId, eventStatusWithDemo, dispatch])

    let scene = (
        <div className="d-flex justify-content-center align-items-center mt-5">
            <Spinner animation="border" />
        </div>
    )
    if (config.data?.configs?.length) {
        const currentConfig = config.data.configs[0]
        const currentTeam = config.data.teams.find((t) => t.pk == currentConfig.fields.team)
        configFields = { ...currentConfig.fields }
        teamFields = currentTeam ? { ...currentTeam.fields } : {}
        analyticsData.enabled = configFields.analytics
        analyticsData.configId = currentConfig.pk
        analyticsData.teamId = currentTeam.pk
        analyticsData.sportId = sportId
        const adjust = parseInt(new URLSearchParams(props.location.search).get("adjust") || 0)

        let streamFormat = STREAM_FORMAT_HLS
        if (new URLSearchParams(props.location.search).get("mp4") || configFields.video1?.split(".").pop() === "mp4") streamFormat = STREAM_FORMAT_MP4
        else if (eventStatus === STREAM_FORMAT_WEBRTC) streamFormat = STREAM_FORMAT_WEBRTC

        if (new URLSearchParams(props.location.search).get("vr")) {
            scene = <VRScene adjust={adjust} streamFormat={streamFormat} sports={sports} config={configFields} team={teamFields} eventStatus={eventStatusWithDemo} />
        } else {
            switch (sportId) {
                case "baseball":
                    scene = (
                        <BaseballScene
                            adjust={adjust}
                            streamFormat={streamFormat}
                            sports={sports}
                            config={configFields}
                            team={teamFields}
                            eventStatus={eventStatusWithDemo}
                            analyticsData={analyticsData}
                        />
                    )
                    break
                case "soccer":
                    scene = (
                        <SoccerScene
                            adjust={adjust}
                            streamFormat={streamFormat}
                            sports={sports}
                            config={configFields}
                            team={teamFields}
                            eventStatus={eventStatusWithDemo}
                            analyticsData={analyticsData}
                        />
                    )
                    break
                case "nascar":
                    scene = (
                        <NascarScene
                            adjust={adjust}
                            streamFormat={streamFormat}
                            sports={sports}
                            config={configFields}
                            team={teamFields}
                            eventStatus={eventStatusWithDemo}
                            analyticsData={analyticsData}
                        />
                    )
                    break
                default:
                    break
            }
        }
    } else if (config.error) {
        console.log(config.error)
        //log error
        return <Redirect to="/" />
    }

    return scene
}
