import { useEffect, useState } from "react"
import { Modal, Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { checkEventSubscribersData, updateEventSubscriber } from "../../store/event-subscribers-slice"
import { fetchProfileData } from "../../store/profile-slice"

export default function Subscribe(props) {
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth)
    const profile = useSelector((state) => state.profile)
    const eventSubscribers = useSelector((state) => state.eventSubscribers)
    const [isSubmitting, setIsSubmitting] = useState(false)
    useEffect(() => {
        if (auth.loggedIn) {
            if (!profile.loaded) dispatch(fetchProfileData())

            dispatch(checkEventSubscribersData(props.event.id))
        }
        setIsSubmitting(false)
    }, [auth.loggedIn, profile.loaded, props.event.id, dispatch])

    let modalBody = <Spinner animation="border" />
    let eventSubscribersBody = <Spinner animation="border" />
    let sendToEmailBody = null

    const handleSubscribe = () => {
        setIsSubmitting(true)
        dispatch(updateEventSubscriber(props.event.id))
    }

    if (auth.loggedIn) {
        if (eventSubscribers.loaded) {
            if (eventSubscribers.data.length === 0) {
                eventSubscribersBody = null

                if (profile.loaded && profile.data.email) {
                    sendToEmailBody = (
                        <>
                            <div className="alert alert-warning">
                                <p>
                                    <strong>NOTE: </strong> If you want to change your email, please {<Link to="/profile">visit your profile</Link>}.
                                </p>
                                <ul>
                                    <li>
                                        You are subscribing to event:{" "}
                                        <strong>
                                            {props.event.event_name} - {props.event.title}
                                        </strong>
                                    </li>
                                    <li>
                                        We will send you an email here: <strong>{profile.data.email}</strong>
                                    </li>
                                </ul>
                            </div>
                            {isSubmitting ? (
                                <Spinner animation="border" style={{ width: "16px", height: "16px" }} />
                            ) : (
                                <button className="btn btn-primary" onClick={handleSubscribe}>
                                    Subscribe to this event
                                </button>
                            )}
                        </>
                    )
                } else {
                    sendToEmailBody = (
                        <div className="alert alert-warning">
                            <p>Your email has not been set! Please go {<Link to="/profile">to your profile</Link>} and set an email then come back here</p>
                        </div>
                    )
                }
            } else {
                eventSubscribersBody = (
                    <div className="alert alert-success">
                        <p>Congratulations, you are now subscribed to this event.</p>
                    </div>
                )
            }
        }
        modalBody = (
            <>
                <p>A notification will be sent to you 5 minutes before the start of the Event you select. Remember to move your phone to experience LIVE 360 event.</p>
                {eventSubscribersBody}
                {sendToEmailBody}
            </>
        )
    } else {
        modalBody = (
            <div className="alert alert-warning">
                <p>
                    <strong>You need to be logged in before you can subscribe to this event.</strong>
                </p>
                <ul>
                    <li>
                        If you don&apos;t have an account, please <Link to="/auth/signup">sign up here</Link>
                    </li>
                    <li>
                        If you have an account, please <Link to={`/auth/login?next=/${props.sportId}/events`}>log in here</Link>
                    </li>
                </ul>
            </div>
        )
    }

    return (
        <Modal show={true} onHide={props.handleSubscribeClose} keyboard={false}>
            <Modal.Header>
                <Modal.Title>Let us take you into the action</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">{modalBody}</Modal.Body>
        </Modal>
    )
}
