import { useState } from "react"
import { Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Layout from "../../components/Layout/Layout"
import { authSignup } from "../../store/auth-slice"

export default function Events() {
    const dispatch = useDispatch()
    const [formValues, setFormValues] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const auth = useSelector((state) => state.auth)
    const handleFormValuesOnChange = (e) => setFormValues({ ...formValues, [e.target.getAttribute("data-k")]: e.target.value })

    const handleSignup = async (e) => {
        e.preventDefault()
        setIsSubmitting(true)
        await dispatch(authSignup(formValues))
        setIsSubmitting(false)
    }

    let signupPage = (
        <div className="d-flex justify-content-center mt-4">
            <Spinner animation="border" />
        </div>
    )

    if (formValues.username && auth.data?.username === formValues.username) {
        signupPage = (
            <Layout>
                <div className="container">
                    <div className="alert alert-success">
                        <h2>Welcome to FXR360!</h2>
                        <br />
                        <p>Your new account has been successfully created.</p>
                        <p>
                            Please <Link to="/auth/login">click here</Link> to log in with your new username and password.
                        </p>
                    </div>
                </div>
            </Layout>
        )
    } else {
        signupPage = (
            <Layout>
                <div className="container px-5 pb-5">
                    <form onSubmit={handleSignup}>
                        <div className="display-4 pb-md-4">Signup</div>
                        <div className="alert alert-info d-flex flex-column">
                            <div className="form-group">
                                <label htmlFor="id_username">Username:</label>
                                <div className="mb-2">
                                    <small>Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.</small>
                                </div>
                                <input
                                    className="form-control"
                                    type="text"
                                    autoCapitalize="none"
                                    autoComplete="username"
                                    maxLength="150"
                                    required
                                    id="id_username"
                                    data-k="username"
                                    onChange={handleFormValuesOnChange}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="id_first_name">First Name:</label>
                                <div className="mb-2">
                                    <small>Required.</small>
                                </div>
                                <input
                                    className="form-control"
                                    type="text"
                                    autoComplete="first_name"
                                    maxLength="30"
                                    required
                                    id="id_first_name"
                                    data-k="first_name"
                                    onChange={handleFormValuesOnChange}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="id_last_name">Last Name:</label>
                                <div className="mb-2">
                                    <small>Required.</small>
                                </div>
                                <input
                                    className="form-control"
                                    type="text"
                                    autoComplete="last_name"
                                    maxLength="30"
                                    required
                                    id="id_last_name"
                                    data-k="last_name"
                                    onChange={handleFormValuesOnChange}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="id_email">Email:</label>
                                <div className="mb-2">
                                    <small>Required.</small>
                                </div>
                                <input
                                    className="form-control"
                                    type="email"
                                    autoComplete="email"
                                    maxLength="30"
                                    required
                                    id="id_email"
                                    data-k="email"
                                    onChange={handleFormValuesOnChange}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="id_password1">Password:</label>
                                <div className="mb-2">
                                    <small>
                                        Required.
                                        <ul>
                                            <li>Your password can&apos;t be too similar to your other personal information.</li>
                                            <li>Your password must contain at least 8 characters.</li>
                                            <li>Your password can&apos;t be a commonly used password.</li>
                                            <li>Your password can&apos;t be entirely numeric.</li>
                                        </ul>
                                    </small>
                                </div>
                                <input
                                    className="form-control"
                                    type="password"
                                    autoComplete="current-password1"
                                    required
                                    id="id_password1"
                                    data-k="password1"
                                    onChange={handleFormValuesOnChange}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="id_password2">Password confirmation:</label>
                                <div className="mb-2">
                                    <small>Required. Enter the same password as before, for verification.</small>
                                </div>
                                <input
                                    className="form-control"
                                    type="password"
                                    autoComplete="current-password2"
                                    required
                                    id="id_password2"
                                    data-k="password2"
                                    onChange={handleFormValuesOnChange}
                                />
                            </div>
                        </div>
                        {isSubmitting ? <Spinner animation="border" /> : <button className="btn btn-success login-form-btn">Sign up</button>}
                    </form>
                </div>
            </Layout>
        )
    }

    return signupPage
}
