import { useState } from "react"
import { Carousel } from "react-bootstrap"
import CarouselCard from "./CarouselCard"

export default function CarouselDisplay(props) {
    const [index, setIndex] = useState(0)

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex)
    }

    return (
        <Carousel activeIndex={index} onSelect={handleSelect} className="carousel-dark">
            {props.events.map((e, i) => (
                <Carousel.Item key={i}>
                    <CarouselCard {...props} event={e} />
                </Carousel.Item>
            ))}
        </Carousel>
    )
}
