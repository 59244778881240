import { Link, Redirect } from "react-router-dom"
import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Layout from "../../components/Layout/Layout"
import { loginObtainToken } from "../../store/auth-slice"

export default function Login(props) {
    const dispatch = useDispatch()
    const usernameInputRef = useRef()
    const passwordInputRef = useRef()
    const auth = useSelector((state) => state.auth)

    const nextURL = new URLSearchParams(props.location.search).get("next")

    // if (auth.isAuthenticated) return <Redirect to="/" />

    const handleSubmit = (event) => {
        event.preventDefault()
        dispatch(loginObtainToken(usernameInputRef.current.value, passwordInputRef.current.value))
    }

    if (auth.loggedIn) {
        if (nextURL) return <Redirect to={nextURL || "/"} />
        else return <Redirect to={props.location.state?.from?.pathname || "/"} />
    }

    return (
        <Layout>
            <div className="container px-5">
                <form onSubmit={handleSubmit}>
                    <div className="display-4 pb-md-4">Please log in</div>
                    <div className="alert alert-warning">
                        If you don&apos;t have an account, please <Link to="/auth/signup">sign up here</Link>.
                    </div>
                    <div className="alert alert-info d-flex flex-column">
                        <div className="form-group">
                            <label htmlFor="id_username">Username:</label>
                            <input
                                className="form-control"
                                type="text"
                                autoCapitalize="none"
                                autoComplete="username"
                                maxLength="150"
                                required
                                id="id_username"
                                ref={usernameInputRef}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="id_password">Password:</label>
                            <input className="form-control" type="password" autoComplete="current-password" required id="id_password" ref={passwordInputRef} />
                        </div>
                    </div>
                    <button className="btn btn-primary login-form-btn">Log in</button>
                </form>
            </div>
        </Layout>
    )
}
